// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// components
import { DWEB_MAX_WIDTH, BREAKPOINTS } from 'shared/styles/base';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

const Container = styled.section`
  width: 100%;
  max-width: ${DWEB_MAX_WIDTH}px;
  margin: ${({ theme }) => theme.spacing.spacing20} auto ${({ theme }) => theme.spacing.spacing40};
  padding: ${({ theme }) => `0 ${theme.spacing.spacing50}`};
  ${({ theme }) => theme.typography.bodyMedium};

  a {
    color: ${({ theme }) => theme.colors.secondary};
    margin-left: ${({ theme }) => theme.spacing.spacing10};
  }

  h2 {
    ${({ theme }) =>
      theme.typography.titleSmall} @media screen and(min-width: ${BREAKPOINTS.DESKTOP}px) {
      ${({ theme }) => theme.typography.titleXSmall}
    }
  }

  h3 {
    ${({ theme }) => theme.typography.subTitleMedium};

    @media screen and (min-width: ${BREAKPOINTS.DESKTOP}px) {
      ${({ theme }) => theme.typography.titleXSmall}
    }
  }

  a {
    display: inline-block;
    color: ${({ theme }) => theme.colors.secondary};
    margin: ${({ theme }) => `0 ${theme.spacing.spacing10}`};
  }
  .footer-title {
    line-height: normal;
    ${({ theme }) => theme.typography.titleSmall};
  }
`;

function GenericBrandDesc() {
  const dynamicFooterContent = useRemoteConfig('hp.dynamicFooterContent');

  return (
    <Container>
      <h1 className="footer-title">Nykaa Fashion – Online Shopping Website</h1>
      <h2>GADGETS &amp; TECH ACCESSORIES</h2>
      <p>
        Your quest for the best gadgets and accessories ends at Nykaa Fashion. While we keep
        ourselves a step ahead when it comes to fashion, we also bring you the very best from the
        world of gadgets, tech accessories, and more. After all, they do play a role in uplifting
        your overall style!
      </p>
      <p>
        From innovative gadgets and gizmos to tech gear and accessories, discover everything you
        will ever need to boost your productivity and style.
      </p>
      <h3>Cameras and Accessories</h3>
      <p>
        If you have a flair for photography, it&apos;s about time you get a camera! Check out a
        range of
        <a
          href="/gadgets-tech-accessories/cameras-and-accessories/c/7679"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cameras and Accessories
        </a>
        like tripods and LED rings to take your photography to the next level. You will find tons of
        options in terms of features, price, and more.
      </p>
      <h3>Smartwatches</h3>
      <p>
        Think it&apos;s time to add those amazing apps and notifications on your wrist? It&apos;s
        time to invest in a Smartwatch! And what better place to find one than on Nykaa Fashion which brings you stylish
        smartwatches with all the top features. Explore a wide range of offerings from savvy brands
        like
        <a href="/fossil-smart-watches/c/9076" target="_blank" rel="noopener noreferrer">
          Fossil
        </a>
        , Oppo,
        <a href="/designers/fitbit/c/7074" target="_blank" rel="noopener noreferrer">
          Fitbit
        </a>
        ,
        <a href="/designers/pebble/c/8309" target="_blank" rel="noopener noreferrer">
          Pebble
        </a>
        , and more. You will also find plenty of options in Sports Watches,
        <a href="/fitness-trackers/c/8926" target="_blank" rel="noopener noreferrer">
          Fitness Trackers
        </a>
        , Dress Watches, and more.
      </p>
      <h3>Smartphone Cases &amp; Covers</h3>
      <p>
        At Nykaa Fashion, you will always find plenty of
        <a
          href="/gadgets-tech-accessories/cases-and-covers/c/6402"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cases and Covers
        </a>
        to keep your smartphone safe, stylish or both. They are available in various styles and
        materials ranging from Metal, Leather, Rubber, Plastic, Glass, and more. Apart from mobile
        cases, you will find
        <a href="/men/bags/laptop-bags/c/6869" target="_blank" rel="noopener noreferrer">
          Laptops Covers
        </a>
        , Tablet Covers, Headphone Covers, Watch Bands, and more. Get these from top brands like
        Apple, Samsung, and OnePlus, Realme, other popular names such as
        <a href="/chumbak/c/7739" target="_blank" rel="noopener noreferrer">
          Chumbak
        </a>
        ,
        <a href="/designers/macmerise/c/6426" target="_blank" rel="noopener noreferrer">
          Macmerise
        </a>
        , Na-Kd, and
        <a href="/designers/mvyno/c/9047" target="_blank" rel="noopener noreferrer">
          Mvyno
        </a>
        .
      </p>
      <h3>Speakers</h3>
      <p>
        If you are still on the lookout for the perfect
        <a
          href="/gadgets-tech-accessories/speakers/c/6396"
          target="_blank"
          rel="noopener noreferrer"
        >
          Speakers
        </a>
        that suit your audiophile needs and fit your budget, you are guaranteed to find them at
        Nykaa Fashion. From global brands like
        <a href="/designers/jbl/c/6529" target="_blank" rel="noopener noreferrer">
          JBL
        </a>
        and Amazon to top Indian players such as
        <a href="/designers/boat/c/6421" target="_blank" rel="noopener noreferrer">
          Boat
        </a>
        ,
        <a href="/designers/portronics/c/6667" target="_blank" rel="noopener noreferrer">
          Portronics
        </a>
        , and
        <a href="/designers/fingers/c/9042" target="_blank" rel="noopener noreferrer">
          Fingers
        </a>
        , there&apos;s a wide range of brands to choose from - be it for home media systems or your
        car.
      </p>
      <h3>Headphones</h3>
      <p>
        If you&apos;re bored of your old headphones and planning to buy one that truly rocks, look
        no further! At Nykaa Fashion, you will find almost every type of
        <a
          href="/gadgets-tech-accessories/headphones/c/6395"
          target="_blank"
          rel="noopener noreferrer"
        >
          Headphone
        </a>
        for your needs. Available in various designs, sizes, and connectivity types, find
        <a href="/bluetooth-earphones-headphones/c/6771" target="_blank" rel="noopener noreferrer">
          Bluetooth Headphones
        </a>
        ,
        <a href="/true-wireless/c/6770" target="_blank" rel="noopener noreferrer">
          True Wireless Headphones
        </a>
        , Wireless on Ear Headphones, Wireless Over the Ear Headphones, and more from popular brands
        like
        <a href="/designers/boult-audio/c/6425" target="_blank" rel="noopener noreferrer">
          Boult Audio
        </a>
        ,
        <a href="/designers/skullcandy/c/6418" target="_blank" rel="noopener noreferrer">
          Skullcandy
        </a>
        , JBL, Boat, and
        <a href="/designers/hammer/c/6628" target="_blank" rel="noopener noreferrer">
          Hammer
        </a>
        , among others.
      </p>
      <h3>Power Banks And Chargers</h3>
      <p>
        Keep your smartphones, tablets and other media devices always topped up with the best
        Chargers and power banks. At Nykaa Fashion, you will find multi-functional chargers with
        various connectivity options like USB, Firewire, Wireless, USB-C, and Wi-Fi from top brands.
      </p>
      <p>
        If you have the perfect charger at home, you might need a power bank for when you&apos;re on
        the go. Choose from a range of options for your smartphone, tablet, Mp3 player or digital
        camera with capacity going up from 5000mAh to 27000mAh.
      </p>

      <h2>HOME DECOR</h2>
      <p>
        Create a home of your dreams with Nykaa Fashion. Whatever your needs and desires, find
        everything you&apos;re looking for and discover things you never knew you needed to take
        your space to a stylish new level! Explore top home décor brands and get inspired by an
        incredible range of products including designer
        <a href="/home/beddings/bedsheets/c/8531" target="_blank" rel="noopener noreferrer">
          Bed Sheets
        </a>
        ,
        <a href="/home/decor/photo-frames/c/8611" target="_blank" rel="noopener noreferrer">
          Photo Frames
        </a>
        ,
        <a href="/home/decor/wall-decor/mirrors/c/8599" target="_blank" rel="noopener noreferrer">
          Mirrors
        </a>
        ,
        <a href="/planters/c/8657" target="_blank" rel="noopener noreferrer">
          Indoor Planters
        </a>
        ,
        <a href="/curtains/c/8688" target="_blank" rel="noopener noreferrer">
          Curtains
        </a>
        ,
        <a
          href="/home/beddings/quilts-blankets-dohars/blanket/c/8536"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blankets
        </a>
        ,
        <a
          href="/home/beddings/fillers-protectors/pillow/c/8548"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pillows
        </a>
        ,
        <a
          href="/home/decor/floor-coverings/rugs-and-carpets/c/8683"
          target="_blank"
          rel="noopener noreferrer"
        >
          Carpets and Rugs
        </a>
        ,
        <a href="/home/decor/lighting/table-lamp/c/8632" target="_blank" rel="noopener noreferrer">
          Lamps
        </a>
        , and more.
      </p>
      <h3>Wall Decor</h3>
      <p>
        A well-stated wall gives that perfect finishing touch to your home. Nykaa Fashion brings you
        the classiest
        <a href="/home/decor/wall-decor/c/8596" target="_blank" rel="noopener noreferrer">
          Wall Décor
        </a>
        items from popular brands like
        <a href="/exclusivelane/c/6491" target="_blank" rel="noopener noreferrer">
          ExclusiveLane
        </a>
        ,
        <a href="/the-artment/c/10454" target="_blank" rel="noopener noreferrer">
          The Artment
        </a>
        ,
        <a href="/designers/hometown/c/11593" target="_blank" rel="noopener noreferrer">
          HomeTown
        </a>
        , Chumbak,
        <a href="/ellementry/c/9177" target="_blank" rel="noopener noreferrer">
          Ellementry
        </a>
        ,
        <a href="/the-decor-remedy/c/9171" target="_blank" rel="noopener noreferrer">
          The Décor Remedy
        </a>
        , and many more to jazz up the walls.
      </p>
      <h3>Bedding</h3>
      <p>
        Ready to make your bedroom a stylish paradise? You definitely need to explore the vast
        collection of bedding products available at Nykaa Fashion. Get everything ranging from Bed
        sheets, Blankets, Pillows,
        <a href="/home/beddings/pillow-covers/c/8557" target="_blank" rel="noopener noreferrer">
          Pillow Covers
        </a>
        ,
        <a
          href="/home/beddings/cushions-cushion-covers/cushion-covers/c/8533"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cushion Covers
        </a>
        , and all you can possibly think of from brands like
        <a href="/designers/ddecor-live-beautiful/c/9175" target="_blank" rel="noopener noreferrer">
          D&apos;Decor
        </a>
        ,
        <a href="/designers/spaces/c/9781" target="_blank" rel="noopener noreferrer">
          Spaces
        </a>
        ,
        <a href="/maspar/c/9158" target="_blank" rel="noopener noreferrer">
          Maspar
        </a>
        ,
        <a href="/swayam/c/9174" target="_blank" rel="noopener noreferrer">
          Swayam
        </a>
        ,
        <a href="/designers/dreams/c/9834" target="_blank" rel="noopener noreferrer">
          Dreams
        </a>
        ,
        <a href="/pure-home-+-living/c/9543" target="_blank" rel="noopener noreferrer">
          Pure Home + Living
        </a>
        , and more.
      </p>
      <h3>Kitchen &amp; Dining</h3>
      <p>
        At Nykaa Fashion,
        <a href="/home/kitchen-dining/c/8707" target="_blank" rel="noopener noreferrer">
          Kitchen and Dining Essentials
        </a>
        are all about making your hosting nights easier with the finest quality essentials like
        <a href="/home/kitchen-dining/dinnerware/c/8721" target="_blank" rel="noopener noreferrer">
          Dinnerware
        </a>
        ,
        <a href="/serveware/c/8708" target="_blank" rel="noopener noreferrer">
          Serveware
        </a>
        ,
        <a href="/home/kitchen-dining/cutlery/c/8742" target="_blank" rel="noopener noreferrer">
          Cutlery
        </a>
        ,
        <a
          href="/home/kitchen-dining/table-accessories/c/8732"
          target="_blank"
          rel="noopener noreferrer"
        >
          Table Accessories
        </a>
        ,
        <a
          href="/home/kitchen-dining/lunch-essentials/c/8805"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lunch Essentials
        </a>
        and
        <a href="/home/appliances/c/8800" target="_blank" rel="noopener noreferrer">
          Kitchen Appliances
        </a>
        . Whether you are starting fresh or updating your supplies, you will find everything that a
        modern kitchen needs. Explore the widest range of offerings from reputed brands like
        <a href="/wonderchef/c/9268" target="_blank" rel="noopener noreferrer">
          Wonderchef
        </a>
        , Ritu Kumar, Ellementry, ExclusiveLane,
        <a href="/designers/nestasia/c/12244" target="_blank" rel="noopener noreferrer">
          Nestasia
        </a>
        , Chumbak and more.
      </p>

      <h2>NYKAA FASHION LUXE</h2>
      <p>
        If you desire a bit of luxury in life and wish to take your style game up a notch, we bring
        you
        <a href="/luxe/c/6800" target="_blank" rel="noopener noreferrer">
          Nykaa Fashion Luxe
        </a>
        – a segment that offers head-turning styles and statement accessories to all luxury
        connoisseurs including Men, Women, and Kids. Think of popular designer brands and local
        hidden gems with dedicated offerings ranging from luxe watches, apparel, bags, footwear and
        more.
      </p>
      <h3>Luxe for Men</h3>
      <p>
        Find everything from men&apos;s apparel and Watches to bags and personal accessories – all
        made with supreme quality fabrics and materials for a strong style statement.
      </p>
      <p>
        Brands to keep an eye on:
        <a href="/designers/urvashi-kaur/c/147" target="_blank" rel="noopener noreferrer">
          Urvashi Kaur
        </a>
        , Wendell Rodricks,
        <a href="/designers/echke/c/11017" target="_blank" rel="noopener noreferrer">
          Echke
        </a>
        , Carte Blanche, and
        <a href="/designers/zeppelin/c/7798" target="_blank" rel="noopener noreferrer">
          Zeppelin
        </a>
        among others.
      </p>
      <h3>Luxe for Women</h3>
      <p>
        At Nykaa Fashion Luxe, you will find a galore of options across apparel, jewellery,
        accessories, bags, footwear, and lingerie. Browse through the magnificent styles and be
        ready to look and feel like a star.
      </p>
      <p>
        Brands to keep an eye on:
        <a href="/designers/label-ritu-kumar/c/743" target="_blank" rel="noopener noreferrer">
          Label Ritu Kumar
        </a>
        ,
        <a
          href="/designers/first-resort-by-ramola-bachchan/c/9277"
          target="_blank"
          rel="noopener noreferrer"
        >
          First Resort By Ramola Bachchan
        </a>
        , Masaba,
        <a href="/designers/khara-kapas/c/2173" target="_blank" rel="noopener noreferrer">
          Khara Kapas
        </a>
        , and more.
      </p>
      <h3>Luxe for Kids</h3>
      <p>
        Nykaa Fashion Luxe also offers a range of premium quality styles for your little ones – both
        boys and girls. Explore kids&apos; Indian and western wear styles, sleepwear, and infant
        accessories.
      </p>
      <p>
        Brands to keep an eye on:
        <a href="/designers/stokke/c/12047" target="_blank" rel="noopener noreferrer">
          Stokke
        </a>
        ,
        <a href="/designers/little-luxury/c/9666" target="_blank" rel="noopener noreferrer">
          Little Luxury
        </a>
        , Pastito A Pastito,
        <a
          href="/designers/ps-kids-by-payal-singhal/c/8527"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ps Kids By Payal Singhal
        </a>
        and more.
      </p>
      <h2>HIDDEN GEMS</h2>
      <p>
        Nykaa Fashion brings you
        <a href="/hidden-gems/c/7266" target="_blank" rel="noopener noreferrer">
          Hidden Gems
        </a>
        – a curation of niche home grown labels that are deeply rooted in Indian craftsmanship and
        offer pieces that are mindfully handmade by local artisans. These niche labels stay true to
        the ethos of hidden gems by interpreting modern fashion trends and advancing craft
        techniques, giving birth to the most exquisite creations that you will find only on Nykaa
        Fashion.
      </p>
      <p>
        The hidden gems curation presents labels from across the country, offering a beautiful mix
        of traditional and contemporary fashion. From
        <a
          href="/designers/label-shaurya-sanadhya/c/9200"
          target="_blank"
          rel="noopener noreferrer"
        >
          Label Shaurya Sanadhya
        </a>
        ,
        <a href="/designers/nuhh/c/6440" target="_blank" rel="noopener noreferrer">
          Nuhh
        </a>
        , and
        <a href="/designers/rozana-jaipur/c/13153" target="_blank" rel="noopener noreferrer">
          Rozana Jaipur
        </a>
        to
        <a href="/designers/swtantra/c/9571" target="_blank" rel="noopener noreferrer">
          Swtantra
        </a>
        ,
        <a href="/designers/baise-gaba/c/4481" target="_blank" rel="noopener noreferrer">
          Baise Gaba
        </a>
        , and
        <a href="/designers/gulabo-jaipur/c/7287" target="_blank" rel="noopener noreferrer">
          Gulaboo Jaipur
        </a>
        , discover young and vibrant labels that champion modern Indian design.
      </p>
      <p>
        Look out for Indian staples like salwar suits, sarees, kurtas, and lehengas or experiment
        with a range of fusion separates. Indigenous craftsmanship and age-old techniques of
        embroidery, stitching and weaving have a much-deserved moment in these creations. Apart from
        apparel, the hidden gems category also offers a range of accessories, jewellery, footwear,
        bags and more. You will find pieces for every occasion such as Festive, Casual,
        <a href="/formal-dresses/c/4708" target="_blank" rel="noopener noreferrer">
          Formal
        </a>
        ,
        <a href="/semi-formal-dresses/c/4867" target="_blank" rel="noopener noreferrer">
          Semi-Formal
        </a>
        , Wedding, and others.
      </p>

      <h2>STYLE INSPIRATION - LATEST FASHION TRENDS</h2>
      <p>
        From traditional Indian wear to contemporary and into-western wear, Nykaa Fashion brings you
        the latest in fashion in an array of styles. While there are plenty of trends to look out
        for, the world of fashion is greatly inspired by comfortable clothing.
      </p>
      <p>
        Comfortable clothing has become a trend itself and it is certainly here to stay. Nykaa
        Fashion has a special Minimal Utility collection from Twenty Dresses that offers a winning
        combination of style and comfort. So get ready for the ease of movement while being your
        stylish best in dresses made from breathable fabrics with the finest details and soothing
        hues.
      </p>
      <p>
        If you&apos;re looking for Celebrity-Inspired Styles that will have you turning heads, check out the latest designs from
        <a href="/designers/masaba/c/138" target="_blank" rel="noopener noreferrer">
          Masaba
        </a>
        on Nykaa Fashion that have been a huge hit among Bollywood celebs. Known for its whimsical,
        bright prints and fusion wear designs, the label will be your go-to for big events.
      </p>

      <h2>SUSTAINABILITY &amp; NYKAA</h2>
      <p>
        Nykaa Fashion is making every effort to bring sustainable fashion into the limelight through
        its special edit -
        <a href="/lp/sustainable-edit" target="_blank" rel="noopener noreferrer">
          The Responsible Collection
        </a>
        . It features a mix of sustainability-focused labels from both popular and upcoming labels.
        These carefully curated brands represent the advocates of sustainable fashion that focus on
        using organic materials, recycled products, and upcycled products for sustainable
        development.
      </p>
      <p>
        Nykaa Fashion also uses a special &apos;responsible&apos; tag for products that engage in
        sustainable practices. We also deliver 90% of our orders in eco friendly packaging made from
        recyclable paper flyers.
      </p>
      <p>
        Sustainable labels worth checking out: Chumbak, NA-KD,
        <a href="/designers/diwaah/c/7962" target="_blank" rel="noopener noreferrer">
          Diwaah
        </a>
        ,
        <a href="/designers/kicks-and-crawl/c/7865" target="_blank" rel="noopener noreferrer">
          Kicks And Crawl
        </a>
        ,
        <a href="/designers/cavallo-by-linen-club/c/8249" target="_blank" rel="noopener noreferrer">
          Cavallo By Linen Club
        </a>
        ,
        <a href="/designers/charukriti/c/5413" target="_blank" rel="noopener noreferrer">
          Charukriti
        </a>
        ,
        <a href="/designers/ed-a-mamma/c/10989" target="_blank" rel="noopener noreferrer">
          Ed-A-Mamma
        </a>
        ,
        <a href="/designers/khara-kapas/c/2173" target="_blank" rel="noopener noreferrer">
          Khara Kapas
        </a>
        ,
        <a href="/designers/monk-mei/c/9854" target="_blank" rel="noopener noreferrer">
          Monk &amp; Mei
        </a>
        and
        <a href="/designers/paio/c/4341" target="_blank" rel="noopener noreferrer">
          Paio
        </a>
        .
      </p>
      {dynamicFooterContent && <div dangerouslySetInnerHTML={{ __html: dynamicFooterContent }} />}
    </Container>
  );
}

export default memo(GenericBrandDesc);
